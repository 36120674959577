<template>
  <div class='circuitDetail'>
    <el-dialog title="告警时数据" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio()">
      <div class="contDio">
        <el-form class="contDio" ref="dioform" :model="inform" label-width="100px" size="small">
          <el-row>
            <el-col :span="12" v-for="(item,index) in inform.detailData" :key="getUUID()" >
              <el-form-item :label="`${item.name}:`"><span :class="['status',{'success':item.type=='0','warning':item.type=='1'}]">
                {{ item.value }}{{ item.unit }}
              </span></el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl,globalStr} from '@/assets/js/api'
import qs from 'qs'
import {getUUID} from '@/utils/utils'
let vm;
export default {
  name:'',
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度

      inform:{
        detailData:[]
      }
    }
  },
  created(){
    vm = this
  },
  methods:{
    init(type, item) {
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true
      vm.$nextTick(() => {
        if (type == 'detail') {
          vm.dioTit = '设备详情'
          vm.getAlarmData(item.id,item.alarmId)
        }
      })
    },
    //点击查看节点数据
    async getAlarmData(id,alarmId){
      const dataParams = {
        "alarmId":alarmId,
        "alarmDetailId":id
      }
      const res = await apiUrl.alarmDataDetail(qs.stringify(dataParams))
      if(res?.code==200){
        this.inform.detailData = res.data
      }else{
        this.$message.error(res.message)
      }
    },
    //获取uuid
    getUUID() {
      return getUUID()
    },
    //关闭弹窗
    closeDio(formEl) {
      vm.inform = {
        detailData:[]
      }
      vm.isDio = false
    },
  }
}
</script>
<style lang='scss'>
.circuitDetail{

}
</style>